// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-general-enquiry-js": () => import("./../../../src/pages/contact/general-enquiry.js" /* webpackChunkName: "component---src-pages-contact-general-enquiry-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news-detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-overview-js": () => import("./../../../src/pages/news-overview.js" /* webpackChunkName: "component---src-pages-news-overview-js" */),
  "component---src-pages-no-result-no-result-properties-js": () => import("./../../../src/pages/NoResult/NoResultProperties.js" /* webpackChunkName: "component---src-pages-no-result-no-result-properties-js" */),
  "component---src-pages-no-result-property-list-js": () => import("./../../../src/pages/NoResult/PropertyList.js" /* webpackChunkName: "component---src-pages-no-result-property-list-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-for-sale-nego-js": () => import("./../../../src/pages/property/for-sale/nego.js" /* webpackChunkName: "component---src-pages-property-for-sale-nego-js" */),
  "component---src-pages-property-map-for-sale-index-js": () => import("./../../../src/pages/property-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-map-for-sale-index-js" */),
  "component---src-pages-property-map-to-rent-index-js": () => import("./../../../src/pages/property-map/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-map-to-rent-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-area-guide-detail-template-js": () => import("./../../../src/templates/area-guide-detail-template.js" /* webpackChunkName: "component---src-templates-area-guide-detail-template-js" */),
  "component---src-templates-careers-details-template-js": () => import("./../../../src/templates/careers-details-template.js" /* webpackChunkName: "component---src-templates-careers-details-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepage-template.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-office-template-js": () => import("./../../../src/templates/office-template.js" /* webpackChunkName: "component---src-templates-office-template-js" */)
}

